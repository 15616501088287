import FooterLogo from "../assets/images/eyramlogo.png";
import Eyram from "../assets/images/Eyram.jpg";
import Tylen from "../assets/images/Tylen.jpeg";
import { CiInstagram, CiTwitter, CiFacebook } from "react-icons/ci";

export default function About() {
  const facebook = { color: "rgba(29, 78, 216)", fontSize: "1.8em" };
  const twitter = { color: "rgb(14 165 233)", fontSize: "1.8em" };
  const instagram = { color: "rgb(248 113 113)", fontSize: "1.8em" };

  return (
    <div className="container flex flex-col items-center justify-center py-8 pl-0 md:py-10 md:pr-10">
      <div className="mb-10 flex w-[80%] flex-col items-center justify-center md:grid md:grid-cols-3 md:place-items-center">
        <img
          className="col-span-1 h-auto w-40 md:col-span-2 md:w-60"
          src={FooterLogo}
          alt="footer_logo"
        />
        <div className="relative flex flex-col">
          <span className="flex pb-3 md:text-justify">
            Eyram Writes is a blog website which is dedicated in delivering
            authentic information to all its visitors.
          </span>
          <span className="text-green-500 hover:text-green-600">
            info@eyramwrites.com
          </span>
        </div>
      </div>
      <div className="relative mb-12 flex flex-col-reverse items-center justify-center md:grid md:grid-cols-3 md:place-items-center">
        <div className="relative order-last flex h-64 w-64 items-center justify-center rounded-md border border-green-500 bg-white pl-8 pt-8 shadow-md">
          <img
            src={Eyram}
            alt="writer_pic"
            className="absolute flex h-64 w-64 rounded-md shadow-lg"
          />
        </div>
        <div className="my-5 flex w-[80%] flex-col items-center gap-2 md:col-span-2 md:my-0 md:w-[50%] md:gap-4">
          <span className="flex text-2xl font-semibold">Eyram</span>
          <span className="mb-1 flex font-medium text-green-600 md:mb-3">
            Content Writer
          </span>
          <span className="mb-4 flex text-gray-700">
            Eyram Writes is a blog website which is dedicated in delivering
            authentic information to all its visitors.
          </span>
          <div className="text-sky flex w-60 flex-row justify-evenly md:w-40">
            <CiFacebook style={facebook} />
            <CiInstagram style={instagram} />
            <CiTwitter style={twitter} />
          </div>
        </div>
      </div>
      <div className="relative mb-10 flex grid-cols-3 flex-col place-items-center md:grid">
        <div className="relative col-span-2 flex h-64 w-64 items-center justify-center rounded-md border border-green-500 bg-white pl-8 pt-8 shadow-md">
          <img
            src={Tylen}
            alt="editor_pic"
            className="absolute flex h-64 w-64 rounded-md shadow-lg"
          />
        </div>
        <div className="my-5 flex w-[80%] flex-col items-center gap-2 md:gap-4">
          <span className="flex text-2xl font-semibold">Tylen Sam</span>
          <span className="mb-1 flex font-medium text-green-600 md:mb-3">
            Editor
          </span>
          <span className="mb-4 flex text-gray-700">
            Eyram Writes is a blog website which is dedicated in delivering
            authentic information to all its visitors.
          </span>
          <div className="text-sky flex w-60 flex-row justify-evenly">
            <CiFacebook style={facebook} />
            <CiInstagram style={instagram} />
            <CiTwitter style={twitter} />
          </div>
        </div>
      </div>
    </div>
  );
}
