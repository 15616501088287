import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import { BiRightArrow } from "react-icons/bi";

const Category = () => {
  const [cats, setCats] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "category"]{
        title,
        description,
        "count": count(*[_type== "post" && references(^._id)])
    } | order(count desc) [0...10]`
      )
      .then((data) => setCats(data))
      .catch((error) => console.log(error));
  });

  return (
    <div className="hidden h-[400px] flex-col rounded-lg border border-gray-200 p-2 shadow-md md:flex">
      <h2 className="pl-2 text-xl font-medium">Categories</h2>
      <ul className="p-4">
        {cats.map((cat) => (
          <li className="p-2" key={cat.title}>
            <Link to={`/`} className="flex w-full flex-row items-center gap-2">
              <div className="flex w-[80%] items-center gap-2">
                <BiRightArrow color="rgb(71,85,105)" />
                <p className="flex text-slate-600">{cat.title}</p>
              </div>
              <span className="flex w-[20%] text-slate-600">{cat.count}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Category;
