import { useEffect, useState } from "react";
import { client } from "../client";
import { Link } from "react-router-dom";
import { PortableText } from "@portabletext/react";

const SecondSection = () => {
  const [posts, setPosts] = useState([]);

  const projectId = process.env.REACT_APP_PROJECT_ID;
  const dataset = process.env.REACT_APP_DATASET;

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"][0...5] {
        title,
        slug,
        body,
        "authorName": author->name,
        "authorImage": author->image.asset->url,
        mainImage {
          asset -> {
           _id,
           url
          }, 
          alt,
        },
        _createdAt
    }`
      )
      .then((data) => setPosts(data))
      .catch((error) => console.log(error));
  });

  return (
    <div className="relative col-span-3 flex flex-col gap-4 rounded-lg md:col-span-2">
      {posts.map((post) => (
        <Link
          className="relative flex max-h-44 items-center rounded-lg border border-gray-200 shadow-md"
          to={`/${post.slug.current}`}
          key={post.slug.current}
        >
          <div className="flex w-[100%] flex-row">
            <img
              className="flex max-h-44 w-[30%] rounded-l-lg"
              src={post.mainImage.asset.url}
              alt={post.title}
            />
            <div className="flex w-[68%] flex-col p-2 pl-4">
              <h3 className="line-clamp-1 pb-1 text-lg font-semibold md:line-clamp-2">
                {post.title}
              </h3>
              <div className="mb-3 line-clamp-2 text-sm md:line-clamp-3 md:text-justify">
                <PortableText
                  value={post.body}
                  projectId={projectId}
                  dataset={dataset}
                />
              </div>

              <div className="flex w-full flex-row items-center gap-4 pb-1">
                <div className="flex flex-row items-center gap-1">
                  <img
                    className="h-[20px] w-[20px] rounded-full md:h-[40px] md:w-[40px]"
                    src={post.authorImage}
                    alt="author"
                  />
                  <p className="text-sm font-medium">{post.authorName}</p>
                </div>
                <span className="flex text-xs text-green-600">
                  {new Date(post._createdAt).toLocaleDateString("en-gb", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SecondSection;
