import Image404 from "../assets/images/NotFound.svg";

function NotFound() {
  return (
    <div className="flex h-[80vh] w-screen flex-col items-center justify-center overflow-hidden">
      <img src={Image404} alt="404" className="resize-contain h-[70%] w-full" />
      <h1>OOPS NOT FOUND</h1>
    </div>
  );
}

export default NotFound;
