import { useState, useEffect } from "react";
import { CiInstagram, CiTwitter, CiFacebook } from "react-icons/ci";
import Newsletter from "./Newsletter";
import { client } from "../client";
import { Link } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";
import FooterLogo from "../assets/images/eyramlogo.png";

const Footer = () => {
  const [labels, setLabels] = useState([]);

  const facebook = { color: "rgba(29, 78, 216)", fontSize: "1.8em" };
  const twitter = { color: "rgb(14 165 233)", fontSize: "1.8em" };
  const instagram = { color: "rgb(248 113 113)", fontSize: "1.8em" };

  useEffect(() => {
    client
      .fetch(
        `*[_type == "category"]{
        title,
        description,
    }`
      )
      .then((data) => setLabels(data))
      .catch((error) => console.log(error));
  });
  return (
    <footer className="relative bottom-0 mt-4 flex h-auto w-full flex-col items-center border-t-2 border-slate-100 bg-white">
      <div className="flex w-[80%] flex-col-reverse justify-between gap-10 pl-4 pt-4 md:grid md:w-[90%] md:grid-cols-3 md:flex-row md:justify-evenly md:pl-0">
        <div className="flex flex-col">
          <img className="h-20 w-20" src={FooterLogo} alt="footer_logo" />
          <span className="flex pb-3 text-justify">
            Eyram Writes is a blog website which is dedicated in delivering
            authentic information to all its visitors.
          </span>
          <span className="pb-4 text-green-500 hover:text-green-600">
            info@eyramwrites.com
          </span>
        </div>
        <div className="flex flex-col md:items-center">
          <span className="flex font-medium">Labels</span>
          <ul className="p-1">
            {labels.map((cat) => (
              <li className="p-1 text-sm text-green-400" key={cat.title}>
                <Link
                  to={`/`}
                  className="flex w-full flex-row items-center gap-2"
                >
                  <BiRightArrow />
                  <p>{cat.title}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center">
          <Newsletter />
        </div>
      </div>
      <div className="mx-4 my-6 flex w-[80%] flex-row items-center justify-between">
        <h2 className="font-medium">2022 &copy; Copyright</h2>
        {/* Social Icons */}
        <div className="flex w-[30%] items-center justify-between md:w-[10%]">
          <CiFacebook style={facebook} />
          <CiInstagram style={instagram} />
          <CiTwitter style={twitter} />
        </div>
        <h3 className="hidden">Made with ♥ by GTurk</h3>
      </div>
    </footer>
  );
};

export default Footer;
