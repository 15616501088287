import { CiLocationOn, CiMail, CiPhone } from "react-icons/ci";
export default function Contact() {
  return (
    <div className="flex flex-col place-items-center gap-20 px-6 py-10 md:grid md:grid-cols-2 md:p-20">
      <div className="flex w-[80%] flex-col">
        <div className="flex flex-col pb-10">
          <span className="flex text-2xl font-semibold text-green-600 md:text-4xl">
            Get in touch
          </span>
          <span className="flex text-base font-medium md:text-xl">
            Leave us a message and we will be in touch within 24hours
          </span>
        </div>
        <div className="flex flex-col gap-8 md:gap-10">
          <div className="flex items-center gap-2">
            <CiLocationOn size={28} />
            <p>545 Mavis Island</p>
          </div>
          <div className="flex items-center gap-2">
            <CiPhone size={28} />
            <p>+ (555) 234-5678</p>
          </div>
          <div className="flex items-center gap-2">
            <CiMail size={28} />
            <p>info@eyramwrites.com</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-8 overflow-hidden md:gap-10">
        <h3 className="flex text-2xl font-semibold text-green-600 md:text-4xl">
          Leave us a message
        </h3>
        <input
          className="w-72 rounded-md border border-gray-100 p-4 md:w-96"
          placeholder="Name"
        />
        <input
          className="w-72 rounded-md border border-gray-100 p-4 md:w-96"
          placeholder="Email"
        />
        <textarea
          className="h-3/5 w-72 rounded-md border border-gray-100 p-4 md:w-96"
          placeholder="Message"
        />
        <button
          className="flex h-8 w-28 items-center justify-center rounded-md border border-b bg-green-500 p-5 font-medium text-white shadow-md hover:bg-green-600 md:h-6 md:w-24"
          type="submit"
        >
          Send
        </button>
      </div>
    </div>
  );
}
