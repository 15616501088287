import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RecentPosts } from "./index";
import { client } from "../client";
// import Placeholder from "../assets/images/profile_picture.png";

const FirstSection = () => {
  const [head, setHead] = useState(null);

  //Data for new post
  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"][0] {
          title,
          slug,
          body,
          "authorName": author->name,
          "authorImage": author->image.asset->url,
          mainImage {
            asset -> {
            _id,
            url
            }, 
            alt,
          },
          _createdAt
      }`
      )
      .then((data) => setHead(data))
      .catch((error) => console.log(error));
    // console.log(heads);
  });

  if (!head) {
    return null; // Render null or a loading spinner while the data is being fetched
  }

  return (
    <div className="mb-4 grid grid-cols-3 gap-10">
      <Link
        to={`/${head.slug.current}`}
        key={head.slug.current}
        className="col-span-3 rounded-lg shadow-md md:col-span-2"
      >
        <div className="relative flex">
          {head.mainImage && head.mainImage.asset && (
            <img
              src={head.mainImage.asset.url}
              alt={head.title}
              className="flex h-[500px] w-full rounded-lg bg-cover"
            />
          )}

          <div className="absolute bottom-0 flex h-[120px] w-full flex-col rounded-b-lg p-4 backdrop-blur-md backdrop-opacity-60">
            <span className=" mb-4 line-clamp-2 text-2xl font-medium">
              {head.title}
            </span>
            <div className="flex flex-row items-center">
              <div className="flex w-60 flex-row items-center gap-2">
                <img
                  className="h-[40px] w-[40px] rounded-full pr-1 text-green-600"
                  src={head.authorImage}
                  alt="author"
                />
                <p>{head.authorName}</p>
              </div>
              <span className="text-sm">
                {new Date(head._createdAt).toLocaleDateString("en-gb", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        </div>
      </Link>
      <RecentPosts />
    </div>
  );
};

export default FirstSection;
