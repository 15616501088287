import { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/eyramlogo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { CiInstagram, CiTwitter, CiFacebook } from "react-icons/ci";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const facebook = { color: "rgba(29, 78, 216)", fontSize: "1.8em" };
  const twitter = { color: "rgb(14 165 233)", fontSize: "1.8em" };
  const instagram = { color: "rgb(248 113 113)", fontSize: "1.8em" };

  return (
    <header className="sticky top-0 z-50 h-[80px] w-full bg-white p-2 shadow-sm">
      <nav className="mx-auto flex h-[70px] w-[92%] items-center justify-between">
        <NavLink to="/" className="hover:cursor-pointer">
          <img className="w-24 md:w-28" src={Logo} alt="website_logo" />
        </NavLink>
        <div
          className={
            mobileMenuOpen
              ? //Mobile menu
                "absolute left-0 top-[80px] flex min-h-[50vh] w-full justify-center bg-white px-5 pt-10 hover:cursor-pointer md:static md:min-h-fit md:w-auto"
              : //Navbar
                "left-0 top-[100%] hidden items-center justify-center bg-white px-5 hover:cursor-pointer md:static md:block md:min-h-fit md:w-auto"
          }
          onClick={() => setMobileMenuOpen(false)}
        >
          <ul className="flex flex-col gap-8 md:flex-row md:items-center md:gap-[4vw]">
            {/* Nav links */}
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-bold items-center border-b-2 border-green-500"
                  : "hover:text-green-500"
              }
            >
              Home
            </NavLink>
            <NavLink
              to="about"
              className={({ isActive }) =>
                isActive
                  ? "text-bold items-center border-b-2 border-green-500"
                  : "hover:text-green-500"
              }
            >
              About
            </NavLink>
            {/* <NavLink
              to="trending"
              className={({ isActive }) =>
                isActive
                  ? "text-bold items-center border-b-2 border-green-500"
                  : "hover:text-green-500"
              }
            >
              Trending
            </NavLink> */}
            <NavLink
              to="contact"
              className={({ isActive }) =>
                isActive
                  ? "text-bold items-center border-b-2 border-green-500"
                  : "hover:text-green-500"
              }
            >
              Contact Us
            </NavLink>
            {/* Social Icons */}
          </ul>
        </div>
        <div className="flex w-[50%] items-center justify-between gap-4 md:w-[20%]">
          <div className="flex w-[50%] flex-row items-center justify-between gap-4 md:w-[40%]">
            <CiFacebook style={facebook} />
            <CiInstagram style={instagram} />
            <CiTwitter style={twitter} />
          </div>
          <div
            className="w-[10%] cursor-pointer text-xl md:hidden"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
