import { useState, useEffect } from "react";
import { client } from "../client";
import { useParams } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import { Category, RecentPosts } from "../components";
import imageUrlBuilder from "@sanity/image-url";

export default function Single() {
  const [singlePost, setSinglePost] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  const projectId = process.env.REACT_APP_PROJECT_ID;
  const dataset = process.env.REACT_APP_DATASET;

  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    setIsLoading(true); // Set isLoading to true when starting the fetch
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => {
        setSinglePost(data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [slug]);

  // Check if singlePost is not yet defined
  if (isLoading || !singlePost) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="container overflow-hidden first:flex">
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <div className="mx-8 flex flex-row items-center justify-center py-4">
          {/* Main components */}
          <div className="flex w-[100%] flex-col md:w-3/5">
            <div className="flex h-2/5 w-full object-contain">
              {singlePost.mainImage && singlePost.mainImage.asset && (
                <img
                  className="w-[850px] rounded-md"
                  src={urlFor(singlePost.mainImage.asset).url()}
                  alt={singlePost.title}
                  title={singlePost.title}
                />
              )}
            </div>
            <span className="flex pb-5 pt-10 text-2xl font-semibold">
              {singlePost.title}
            </span>
            <PortableText
              className="mt-5 flex pt-2 text-justify"
              value={singlePost.body}
              projectId={projectId}
              dataset={dataset}
            />
            {/* Comment section */}
            <div className="mt-10 flex w-full flex-col gap-3 border-t-gray-200 md:w-3/5">
              <input
                placeholder="Enter your email"
                className="h-10 w-80 rounded-md border border-gray-100 p-4 md:w-[500px]"
              />
              <textarea
                className="h-[200px] w-80 rounded-md border border-gray-100 p-4 md:w-[500px]"
                placeholder="Leave your message"
              />
              <button
                className="flex h-8 w-28 items-center justify-center rounded-md border border-b bg-green-500 p-5 font-medium text-white shadow-md hover:bg-green-600 md:h-6 md:w-24"
                type="submit"
              >
                Send
              </button>
            </div>
            {/* End of comment section */}
          </div>
          {/* Side components */}
          <div className="relative flex w-[30%] flex-col">
            <div className="">
              <RecentPosts />
            </div>
            <div className="relative mt-5 h-48">
              <Category />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
