export default function Newsletter() {
  return (
    <div className="m-2 flex w-full items-center justify-center rounded-md border border-green-300 px-10 pb-10 shadow-sm md:w-4/5">
      <div className="flex flex-col items-center justify-center">
        <span className="m-5 text-xl font-bold">Subscribe to NewsLetter</span>
        <p className="mb-3 text-sm font-medium">
          Subscribe to get all latest stories.
        </p>
        <div className="flex h-[40px] w-[250px] flex-row items-center rounded-md border border-green-300 md:w-[250px]">
          <input
            className="h-[40px] w-[80%] bg-transparent px-4"
            placeholder="Enter Email"
          />
          <button
            type="submit"
            className="flex w-[20%] items-center justify-center rounded-r-md bg-green-400 py-2 uppercase text-white hover:cursor-pointer hover:bg-green-600"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
