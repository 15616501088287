import { Category, FirstSection, SecondSection } from "../components";
import { useEffect, useState } from "react";
import { client } from "../client";
// import SliderCard from "../components/SliderCard";
export default function Home() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        mainImage {
          asset -> {
           _id,
           url
          },
          alt
        }
    }`
      )
      .then((data) => setPosts(data))
      .catch((error) => console.log(error));
    // console.log(posts);
  }, [posts]);

  return (
    <div className="container relative mx-auto flex h-auto flex-col p-4">
      <div className="relative mb-2">
        <FirstSection />
      </div>
      <div className="relative grid grid-cols-3 gap-10">
        <SecondSection />
        <Category />
      </div>
      {/* <div className="relative mt-2 h-auto w-full">
        <SliderCard />
      </div> */}
    </div>
  );
}
