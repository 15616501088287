import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import { PortableText } from "@portabletext/react";

export default function RecentPosts() {
  const [recentPosts, setRecentPosts] = useState([]);

  const projectId = process.env.REACT_APP_PROJECT_ID;
  const dataset = process.env.REACT_APP_DATASET;

  //Data for recent post
  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"][0...4]{
      title,
      slug,
      body,
      mainImage {
        asset -> {
        _id,
        url
        }, 
        alt
        }
    }`
      )
      .then((data) => setRecentPosts(data))
      .catch((error) => console.log(error));
    // console.log(recentPosts);
  });

  return (
    <div className="hidden flex-col rounded-lg border border-gray-200 p-2 shadow-md md:flex">
      <h2 className="text-xl font-medium">Recent Posts</h2>
      {/* Replace with data from backend */}
      {recentPosts.map((recent) => {
        return (
          <Link
            to={`/${recent.slug.current}`}
            key={recent.title}
            className="flex h-[100px] w-full flex-row p-4"
          >
            <img
              src={recent.mainImage.asset.url}
              alt={recent.title}
              className="flex h-[70px] w-[70px] rounded-lg"
            />
            <div className="flex w-[80%] flex-col px-2">
              <h3 className="line-clamp-1 font-semibold">{recent.title}</h3>
              <div className="line-clamp-2 text-justify text-sm">
                <PortableText
                  value={recent.body}
                  projectId={projectId}
                  dataset={dataset}
                />
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
